import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function List(props) {
  //const [items, setItems] = useState(['Item 1', 'Item 2', 'Item 3']);
  const [items, setItems] = useState(props.list);
  const [showInputButton, setShowInputButton] = useState(false);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE"
  });

  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };

  const showInput = () => {
      setShowInputButton(true);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    
    if ("add_value" in formData.data && formData.data.add_value.length === 0) return;
    items.push(formData.data.add_value);
    setItems(items);
    props.callback(props.field_name, items)
    setFormData({
      data: {"add_value":""},
      status: "IDLE"
    });
  };

  const handleRemove = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    props.callback(props.field_name, newItems)
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    props.callback(props.field_name, newItems)
  };

  return (
    <div>
      {!showInputButton && <button onClick={showInput}>+</button>}
      {showInputButton && <form><input name="add_value" value={formData.data.add_value} onChange={handleInputChange}></input><button onClick={handleAdd}>Submit</button></form>}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <li 
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps} 
                      ref={provided.innerRef}
                    >
                      {item}
                      &nbsp;<button onClick={() => handleRemove(index)}>X</button>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default List;
