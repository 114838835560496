import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { createClient, OAuthStrategy } from "@wix/sdk";
import { plans } from "@wix/pricing-plans";
import { redirects } from "@wix/redirects";
const styles = {
   grid: {},
   card: {},
}

const myWixClient = createClient({
  modules: { plans, redirects },
  auth: OAuthStrategy({
    clientId: `498e3f0a-62b1-43ee-8a8d-1ff072217b5b`,
    tokens: JSON.parse(Cookies.get("session") || null),
  }),
});

export default function Subscriptions() {
  const [planList, setPlanList] = useState([]);

  async function fetchPlans() {
     const response = await plans.listPublicPlans({});
    console.log(JSON.stringify(response));
   // const planList = await myWixClient.plans.queryPublicPlans().find();
   
    setPlanList(planList.items);
  }

  async function createRedirect(plan) {
    const redirect = await myWixClient.redirects.createRedirectSession({
      paidPlansCheckout: { planId: plan._id },
      callbacks: { postFlowUrl: window.location.href },
    });
    window.location = redirect.redirectSession.fullUrl;
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className={styles.grid}>
      <div>
        <h2>Choose Plan:</h2>
        {planList.map((plan) => {
          return (
            <div
              className={styles.card}
              key={plan._id}
              onClick={() => createRedirect(plan)}
            >
              {plan.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

