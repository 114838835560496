import React, { useState } from "react";

import { withRouter } from "react-router";
import { useMyContext } from '../MyContext';

import { Navbar, Nav, Button } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../index.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSearch,
  faCog,
  faUsersCog,
  faUser,
  faHome,
  faEnvelope,
  faCloudUploadAlt
} from "@fortawesome/free-solid-svg-icons";

//Components
import MenuButton from "../components/MenuButton";
import WixLoginBar from "../components/WixLoginBar";

function Header(props) {
  const { reference } = props;
  const { users } = props;
  const { totalUsers } = props;
  const { data } = useMyContext();
  const [searchVal, setSearchVal] = useState();
  const [activeMenu, setActiveMenu] = useState("agents");

  /* next 3 lines will set the appropriate menu to active upon entry of the app */
  const location = useLocation();
  const pathname = location.pathname.substring(1);
  //if (pathname.length > 0) setActiveMenu(pathname);

/* NOT USED CURRENTLY...used if we have a search field in the header 
  const searchChange = val => {
    if (val && val.length) {
      setSearchVal(val[0]);
    } else {
      setSearchVal("");
    }
  };
  const submitSearch = () => {
    if (searchVal) {
      if (typeof searchVal === 'object' && searchVal !== null) {
        console.log(JSON.stringify(searchVal));
        typeahead.getInstance().clear();
        props.history.push(`/search/` + searchVal.name);
        console.log("IN HERE OBJECT");
      }
      else {
        typeahead.getInstance().clear();
        props.history.push(`/search/${searchVal}`);
        console.log("IN HERE");
      }
    }
    else {
      console.log("IN HERE 2");
      // GET VALUE FROM DOM
    }
  };
  let typeahead; * just leaving in for future use *
*/

  const editProfile = () => {
    props.history.push(`/user`);
  };


  function isAdmin(props) {
     if (props.userDeep && props.userDeep.roles && props.userDeep.roles.toLowerCase().indexOf("admin") >= 0) return true;

     return false;
  }

  /* Need space between when 2 bars */
  const style = {
     display: "flex",
     justifyContent: (props.user !== ""?"space-between": "end")
  }

  //className="mr-2"
  //allowNew
  return (
     <>
    <Navbar variant="light" expand="lg">
      <IndexLinkContainer to="/" activeClassName="">
        <Navbar.Brand href="#">
          <FontAwesomeIcon icon={faUsersCog} className="mr-2" size="lg" />
          GenAI Agent Editor
        </Navbar.Brand>
      </IndexLinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" style={style}>
        {props.user !== "" &&
          <Nav className="ml-4">
            <Navbar.Text style={{ padding: "5px" }}>
              <Link to={"subscribe"} className={activeMenu === 'subscribe' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('subscribe') }} >Subscribe</Link>
            </Navbar.Text>
            <Navbar.Text style={{ padding: "5px" }}>
              <Link to={"agents"} className={activeMenu === 'agents' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('agents') }} >Agents</Link> 
            </Navbar.Text>
            <Navbar.Text style={{ padding: "5px" }}>
              <Link to={"stats"} className={activeMenu === 'stats' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('stats') }} >Stats</Link>
            </Navbar.Text>
            <Navbar.Text style={{ padding: "5px" }}>
              <Link to={"help"} className={activeMenu === 'help' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('help') }} >Help</Link>
            </Navbar.Text>
            <Navbar.Text style={{ padding: "5px" }}>
              <Link to={"contact"} className={activeMenu === 'contact' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('contact') }} >Contact</Link>
            </Navbar.Text>
            {isAdmin(props) &&
              <Navbar.Text style={{ padding: "5px" }}>
                <Link to={"admin"} className={activeMenu === 'admin' ? 'active nav-link' : 'nav-link'}
              onClick={() => { setActiveMenu('admin') }} >Admin</Link>
              </Navbar.Text>
            }
            </Nav>
        }
        <Nav className="ml-4" style={style}>

          <Navbar.Text style={{ padding: "5px" }}>
              {" "}{data["environmentName"] || ""}{"  "}
          </Navbar.Text>
          <Navbar.Text style={{ padding: "5px" }}>
            { !props.iframeLogin &&
               <WixLoginBar user={props.user} callback={props.callback} />
            }
          </Navbar.Text>
          </Nav>
      </Navbar.Collapse>
    </Navbar>
     </>
  );
}

export default withRouter(Header);
/*
      <Nav className="ml-2 mr-auto">
        <Button variant="primary" onClick={() => {props.history.push("/")}}>
            <FontAwesomeIcon icon={faHome} size="1x" />
            {" "}Home 
        </Button>
      </Nav>
              <Link to={"agents"}>
                <MenuButton title="Agents"></MenuButton>
              </Link>
*/
