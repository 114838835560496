import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import GlobalContext from '../../GlobalContext';
import { useMyContext } from '../../MyContext';


//Components
import TopMargin from "../TopMargin";
import RowMargin from "../RowMargin";
import ShowAgent from "../ShowAgent";
import SuperAppObject from "./SuperAppObject";
import PrintOut from "../PrintOut";
import likeImage from './like_PNG17.png'

var trackingConfig = {
   "collection": "tracking",
   "buttonName": "Save Comment",
   "fields": [
      {
         placeholder: "",
         display_name: "ID",
         name: "remoteID",
         input_type: "text",
         default: "{ID}",
         required: true
      },
      {
         placeholder: "",
         display_name: "Tracking Type",
         name: "trackingType",
         input_type: "dropdown",
         values: ["Comment"],
         required: true
      },
      {
         placeholder: "",
         display_name: "Short Description",
         name: "trackingShortDescription",
         input_type: "text",
         required: true
      },
      {
         placeholder: "",
         display_name: "Comment",
         name: "trackingText",
         input_type: "textarea",
         required: true
      }],

   "table": [{ selector: (row) => row.trackingType, name: "Action", sortable: true, minWidth: '20%' },
   { selector: (row) => row.trackingShortDescription, name: "Description", sortable: true, minWidth: '60%', wrap: true},
   { selector: (row) => row.trackingDate, name: "Date", sortable: true, sortOrder: "asc", minWidth: '20%' }],
   "filterField": "trackingShortDescription",
   "exportFields": [],
   "otherTableOptions": { "paginationPerPage": 20 },
   "actions": "create"
}

var filesConfig = {
   "collection": "files",
   "buttonName": "",
   "fields": [
      {
         placeholder: "",
         display_name: "File Name",
         name: "fileName",
         input_type: "text",
         link: "/getFile/[shortFileName]",
         default: "{ID}",
         required: true
      }
   ],
   "table": [{ selector: (row) => row.shortFileName, name: "File Name", sortable: true, minWidth: '20%' }],
   "filterField": "fileName",
   "exportFields": [],
   "otherTableOptions": { "paginationPerPage": 20 },
   "actions": "create"
};
var quickRepliesConfig = {
   "collection": "quickReplies",
   "buttonName": "",
   "security": {
      "create": "AllUsers",
      "edit": "AllUsers",
      "delete": "AllUsers",
      "view": "AllUsers",
   },
   "fields": [
      {
         placeholder: "",
         display_name: "Content",
         name: "content",
         input_type: "text",
         default: "",
         required: true
      },
      {
         placeholder: "",
         display_name: "Type",
         name: "type",
         input_type: "text",
         default: "prompt",
         required: true
      }
   ],
   "table": [ { selector: (row) => row.content, name: "Content", sortable: true, minWidth: '20%'}, { selector: (row) => row.type, name: "Type", sortable: true, minWidth: '20%'} ],
   "filterField": "content",
   "exportFields": [],
   "otherTableOptions": { "paginationPerPage": 20 },
   "actions": "create"
};

const back = (props) => {
   props.setDisplayState("TABLE");
   props.setActionType("DISPLAY");
};

export default function DisplayDetails(props) {

   const globalData = useContext(GlobalContext);
   const myContext = useMyContext();

   const [validated, setValidated] = useState(false); /* for Bootstrap form validation */
   const [validationString, setValidationString] = useState(""); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */
   const [actionType, setActionType] = useState(props.actionType);
   const [attachmentsData, setAttachmentsData] = useState([]);
   const [displayArray, setDisplayArray] = useState([]);
   const [formData, setFormData] = useState({
      data: props.this_object,
      status: "IDLE"
   });
   const [scanning, setScanning] = useState(false);

   //console.log(JSON.stringify(props));
   const arrayChunk = (arr, n) => {
      const array = arr.slice();
      var colCounter = 0;
      var colArray = [];
      var rowArray = [];
      for (let x = 0; x < arr.length; x++) {
         var element = arr[x];
         if (element.input_type === "none") {
            x++;
            element = arr[x];
         }

         var colspan = 1;
         if ("colspan" in element) {
            colspan = element["colspan"];
            if (colspan === 3) colCounter = n - 1;
            if (colspan + colCounter >= n) {
               colCounter = n - 1;
            }
         }
         colArray.push(element);
         colCounter++;
         if (colCounter === n) {
            rowArray.push(colArray);
            colArray = [];
            colCounter = 0;
         }
      }
      rowArray.push(colArray);
      if (globalData.debug) console.log(rowArray);
      return rowArray;
   };
    const handleCopyClick = async (text) => {
        try {
            await window.navigator.clipboard.writeText(text);
            alert("Copied to clipboard!");
        } catch (err) {
            console.error( "Unable to copy to clipboard.", err);
            alert("Copy to clipboard failed.");
        }
    };

   const handleCancel = async () => {
      props.setDisplayState("TABLE");
   }

   function canEdit(props, config, formData) {

      if (props.canEdit === "false") return false;

      if (props.userDeep && props.userDeep.roles && props.userDeep.roles.toLowerCase().indexOf("edit") >= 0) return true;

      if (config.security && config.security.edit && config.security.edit.indexOf("AllUsers") >= 0)
         return true;

      if (config.security && config.security.edit && config.security.edit.indexOf("createdBy") >= 0 && props.userDeep && formData.data.user && props.userDeep.user === formData.user)
         return true;

      return false;
   }
   const editRecord = () => {
      props.setDisplayState("CREATE_EDIT");
      props.setActionType("EDIT");
   };
   function canDeleteRow(props, config, rowData) {

      if ("locked" in rowData && rowData["locked"] === true) return false;

      if (props.userDeep && props.userDeep.roles && props.userDeep.roles.toLowerCase().indexOf("delete") >= 0) return true;

      if (config.security && config.security.delete && config.security.delete.indexOf("AllUsers") >= 0)
         return true;
      if (config.security && config.security.delete && config.security.delete.indexOf("createdBy") >= 0 && props.user && rowData.user && props.user === rowData.createdBy)
         return true;

      return false;
   }
   const handleDelete = async () => {
      // NEED SOME ERROR HANDLING HERE
      if (formData.data.locked === true) {
         window.confirm("This record is locked. You cannot delete it.");
         return;
      }
      if (globalData.debug) console.log(props.config.collection, "," + formData.data.id);
      await fetch("api/deleteObject/" + props.config.collection + "/" + formData.data.id + "/" + props.user)
         .then(response => response.json())
         .then(data => { if (globalData.debug) console.log(data); setTimeout(() => { props.loadObjects() }, 1000) })
         .catch(function (error) {
            console.log(error);
         })

      //setTimeout(() => {props.loadObjects()}, 5000)
      props.setDisplayState("TABLE");
      props.setActionType("DISPLAY");
   }

   const handleClone = async () => {
      // NEED SOME ERROR HANDLING HERE

      if (globalData.debug) console.log(props.config.collection, "," + formData.data.id);
      await fetch("api/cloneObject/" + props.config.collection + "/" + formData.data.id + "/" + props.user)
         .then(response => response.json())
         .then(data => { if (globalData.debug) console.log("Result from clone = " + JSON.stringify(data)); })
         .catch(function (error) {
            console.log(error);
         })

      setTimeout(() => { props.loadObjects() }, 1000)
      props.setDisplayState("TABLE");
      props.setActionType("DISPLAY");
   }
   const uploadAttachment = () => {
      props.setDisplayState("UPLOAD_ATTACHMENT");
      props.setActionType("UPLOAD_ATTACHMENT");
   }

   const scanWebsite = async () => {
      alert("Scanning " + formData.data["website"] + " and building search index");
      setScanning(true);
      console.log("in scanWebsite");
      const response = await fetch("api/scanWebsite/" + encodeURIComponent(formData.data["website"]) + "/" + formData.data["id"] + "/" + props.user)
      console.log(response);
      const data = await response.json();
      console.log(data);
       //if (globalData.debug) 
       console.log("Result from scan = " + JSON.stringify(data));
       if ("status" in data && data.status === 'failed')
          alert("Website scanning failed: " + data.error);
       if ("status" in data && data.status === 'success')
          alert("Website scanning was successful");
      setScanning(false);
   }


   const refreshDetails = (props) => {
      props.loadObject(formData.data["id"]);
      setTimeout(() => { props.setDisplayState("X"); props.setDisplayState("DISPLAY"); }, 1000)
   };
   const capitalize = (field) => {
       return field[0].toUpperCase() + field.substring(1);
   }
   const reformatFieldWithNewLines = (fieldWithNewLines) => {
         var strArray = fieldWithNewLines.split("\n");
         const finalArray = []
         {
            strArray.map(str => {
               finalArray.push(<div>{str}</div>)
               return true;
            })
         }
         return finalArray;
   }
   const showField = (field, formData) => {

      var fieldData = formData.data[field.name] || "";

      /* TODO : Need to find a better way to read BQ timestamp formats..that is 100% why this one if statement is here */
      if (typeof fieldData  === "object" && "value" in fieldData) {
         fieldData = fieldData["value"]; 
      }

      if (field.input_type === 'list') {
         const finalArray = []
         if (Array.isArray(fieldData)) {
            fieldData.map(str => {
               finalArray.push(<div>{str}</div>)
               return true;
            })
         }
         return finalArray;
      }
      if (field.input_type === 'dropdown') {
/* TODO: can't be hardcoded in the generic SuperAppObject */
         if ((field.values === 'myObjects') && props.all_objects) {
            for (let x=0;x<props.all_objects.length;x++) {
               if (props.all_objects[x]["id"] === fieldData) {
                  return <>{props.all_objects[x]["agentName"]} - {props.all_objects[x]["agentType"]}</>
               }
            }
         }
         if (fieldData.length > 0) 
            return <>{fieldData}</>
         else
            return <>Not Specified</>
      }

      if (field.input_type === 'checkbox') {
         return <>{(fieldData?capitalize(fieldData + ""):"False")}</>
      }

      if ((field.input_type === 'string') && (fieldData.toLowerCase().endsWith(".png") || fieldData.toLowerCase().endsWith(".gif") || fieldData.toLowerCase().endsWith(".jpeg") || fieldData.toLowerCase().endsWith(".jpg") || fieldData.toLowerCase().endsWith(".webp") || fieldData.toLowerCase().endsWith(".svg")))
         return <><div>{fieldData}</div><img src={fieldData} width="100%" /></>

      /* this is for fields that have links which need us to search and replace before showing */
      if (typeof field.display_template !== 'undefined') {
         const str = field.display_template.replace("[" + field.name + "]", fieldData).replace("[agentURL]", myContext["data"]["agentURL"]);
         var result = [];
         if (field.show_copy_button) {
            result.push(<div style={props.style.copyButton}><Button onClick={() => handleCopyClick(str)}>Copy</Button></div>);
         }
         
         result = result.concat(reformatFieldWithNewLines(str));
         if (field.display_x_rows) {
            result = result.slice(0, field.display_x_rows);
         }
         return result
      }
      else if (typeof field.link !== 'undefined') {
         return <a href={field.link.replace("[" + field.name + "]", fieldData).replace("[agentURL]", myContext["data"]["agentURL"]) } target="_blank" rel="noopener noreferrer">{fieldData}</a>

      }
      else if (field.flags && field.flags.indexOf("href") >= 0) {

         return <a href={fieldData} target="_blank" rel="noopener noreferrer">Link</a>
      }
      else if (field.input_type === 'textarea' && fieldData.length > 0 && fieldData.indexOf("\n") >= 0) { /* this is higher priority */
         if (field.display_as_html) {
             return <div dangerouslySetInnerHTML={{__html: fieldData}}></div>
         }
         else {
            var displayString = reformatFieldWithNewLines(fieldData);
            if (field.display_x_rows) {
               displayString = displayString.slice(0, field.display_x_rows)
            }
            return displayString; 
         }
      }
      else if (fieldData && typeof fieldData === "boolean") {
         const displayString = fieldData + "";
         return <>{capitalize(displayString)}</>
      }
      else if (fieldData && fieldData.length > 0) {
         if (field.input_type === "color") {
            const style = {
               backgroundColor: fieldData
            }
            return <>{fieldData} <div style={style}>&nbsp;&nbsp;</div></>
         }
         else if (field.flags && field.flags.indexOf("date") >= 0)
            return <>{fieldData.substring(0, 10)}</>
         else if ((fieldData === true || fieldData === false))
            return <>{capitalize(fieldData)}</>
         else
            return <>{fieldData}</>

      }
      else {
         return <>Not Specified</>
      }
   }
   const handleLike = async () => {
      if (globalData.debug) console.log("LIKE clicked");
      if (!("Likes" in formData.data))
         formData.data["Likes"] = [];
      formData.data["NumLikes"] = 0

      if (!formData.data["Likes"].includes(props.user)) {
         formData.data["Likes"].push(props.user);
         formData.data["NumLikes"] = formData.data["Likes"].length
      }
      else {
         var done = false;
         for (var x = 0; x < formData.data["Likes"].length && !done; x++) {
            if (formData.data["Likes"][x] === props.user) {
               formData.data["Likes"].splice(x, 1);
               formData.data["NumLikes"] = formData.data["Likes"].length
               done = true;
            }
         }
      }
      const response = await fetch("/api/saveObject/" + props.config.collection, {
         headers: { "Content-Type": "application/json" },
         method: "post",
         body: JSON.stringify(formData.data)
      });
      const data = await response.json();
      setFormData({
         ...formData,
         status: "IDLE"
      });
   };
   useEffect(() => {
      console.log("PROPS DATA = " + JSON.stringify(props.this_object));
      setDisplayArray(arrayChunk(props.config.fields, 3));
   }, []);


   return (
      <Container>
         <Row>
            <Col>
               <TopMargin />
               <Row style={props.style.rowStyle}>
                  <div style={{ width: 90 + '%' }}>
                     {<Button onClick={e => back(props)}>Back</Button>} {canEdit(props, props.config, formData.data) && (<Button onClick={e => editRecord()}>Edit</Button>)} {canDeleteRow(props, props.config, formData.data) && (<Button onClick={e => window.confirm("Are you sure you wish to delete this object?") && handleDelete()} >Delete</Button>)} {canDeleteRow(props, props.config, formData.data) && (<Button onClick={e => window.confirm("Are you sure you wish to clone this object?") && handleClone()} >Clone</Button>)} {"flags" in props.config && props.config.flags.indexOf("attachments") >= 0 && formData.data["agentType"] === 'PDF_QA' ? <Button onClick={e => uploadAttachment()}>Upload File(s)</Button> : ""}  {props.depth == 1&&<Button onClick={e => refreshDetails(props)}>Refresh</Button>} { "website" in formData.data && formData.data["website"].length > 0 && <Button disabled={scanning} onClick={e => scanWebsite()}>Scan Website</Button> }
                  </div>
                  <div style={{ 'text-align': 'right', width: 10 + '%' }}>
                     {props.config && props.config.showLikes && (
                        <Button onClick={handleLike}>
                           {"Likes" in formData.data && formData.data.Likes.includes(props.user) &&
                              <div>Liked:</div>
                           }
                           {"Likes" in formData.data && !formData.data.Likes.includes(props.user) &&
                              <img src={likeImage} width="20px" alt={"Like"} />
                           }
                           {!("Likes" in formData.data) &&
                              <img src={likeImage} width="20px" alt={"Like"} />
                           }
                           {"Likes" in formData.data && formData.data.Likes.length > 0 &&
                              <div>{formData.data.Likes.length}</div>
                           }
                        </Button>
                     )
                     }
                  </div>
               </Row>

               <RowMargin />
               {displayArray.map((row, i) => (
                  <div key={i} className="row">
                     {row.map((field, i) => {
                        /* show some fields only if condition is satisfied */
                        if (typeof field.conditional !== 'undefined') {
                           // ONLY SUPPORT == now...must be space delimited
                           const values = field.conditional.split(" ");
                           if (formData.data[values[0]] !== values[2])
                              return(<></>);
                        }
                        if (field.input_type === "none")
                           return(<></>);
                        if ("header" in field) {
                           return (
                              <>
                                 <div style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                                    <div style={{fontSize: "20px", backgroundColor: "rgb(50, 161, 17)", color: "white", marginRight: "30px", paddingLeft:"16px"}}>

                                       <b>{field.header}</b>
                                    </div>
                                 </div>
                              </>
                           )
                        }
                        else {
                           return (
                              <>
                                 <div style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                                    <div style={props.style.displayName}>{field.display_name}{field.required === true ? " (*):" : ":"}</div>
                                    <div style={props.style.displayValue}>{showField(field, formData)}</div>
                                 </div>
                              </>
                           )
                        }
                     }
                     )}
                  </div>
               ))}
               <RowMargin />
               <Row style={props.style.rowStyle}>
                  <Col style={{ width: 100 + '%' }}>
                     {"subTables" in props.config && props.config.subTables.map(table => {
                     if (table.collection in formData.data)
                        return (<>
                           <SuperAppObject depth={props.depth + 1} style={props.style} collection_name={table.collection} all_objects={formData.data[table.collection]} this_object={props.this_object} config={props.config["subTables"][0]} user={props.user} userDeep={props.userDeep} collection_name={table.collection} input_data={formData.data[table.collection]} display_state="TABLE" top_level_save={props.top_level_save} />
                           <RowMargin />
                        </>
                        )
                        //return true;
                     })}

                     {"flags" in props.config && props.config.flags.indexOf("attachments") >= 0 && "attachments" in formData.data &&
                        <>
                           <SuperAppObject depth={props.depth + 1} style={props.style} config={filesConfig} user={props.user} userDeep={props.userDeep} collection_name="files" input_data={formData.data["attachments"]} display_state="FILE_TABLE" />
                           <RowMargin />
                        </>
                     }
                     {"tracking" in formData.data &&
                        <>
                        <SuperAppObject depth={props.depth + 1} style={props.style} config={trackingConfig} user={props.user} userDeep={props.userDeep} collection_name="tracking" input_data={formData.data["tracking"]} display_state="TRACKING_TABLE" />
                        </>
                     }
                  </Col>
               </Row>
            </Col>
            {props.showAgent &&
               <Col xs={4}>
                  <ShowAgent agent_id={formData.data["id"]} />
               </Col>
            }
         </Row>
      </Container>
   )
}
/*
              <SuperAppObject style={props.style} config={trackingConfig} user={props.user} userDeep={props.userDeep} collection_name="tracking" remote_id={formData.data["id"]} display_state="TRACKING_TABLE" />
                     {"row" in formData.data && "attachments" in props.originalData[formData.data["row"]] && props.originalData[formData.data["row"]].attachments.map(field => {
                        var link = "/api/getFile/" + field;
                        return (
                           <Row style={props.style.rowStyle}>
                              <div class="colStyle100">
                                 <a href={link} target="_blank" rel="noopener noreferrer">{field.substring(field.lastIndexOf("-") + 1)}</a>
                              </div>
                           </Row>
                        );
                     }
                     )}
*/
