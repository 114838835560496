import React from "react";

//Components
import RowMargin from "./RowMargin";

export default function NotLoggedInMessage(props) {

  return (
    <React.Fragment>
      {!props.user && props.showLoginErrorMessage &&
        <>
          <RowMargin />
          <h1 style={{ paddingLeft: "100px"}} >Welcome to our Agent/Chatbot Editor</h1>
          <div style={{ fontSize: "20px", textAlign: "left", paddingTop: "30px", paddingLeft: "100px" }}>
            Using this tool, you can create different types of Chatbots/Agents for your business website, or your organization's internal Intranets.
           <ul>
            <li> If you have an account already, please click on the Login button to access the tool</li> 
            <li> If you are a customer of WIX, please click <a href="http://www.wix.com">here</a> to get access through the WIX marketplace</li> 
            <li> If you want to create ChatBots/Agents for other websites (than WIX) or mobile devices, please login to get a free account to try our solution </li>
            <p></p>
           </ul>
          </div>
          <RowMargin />
        </>
      }
    </React.Fragment>
  );
}
