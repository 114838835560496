import React, { useEffect } from "react";

//Components
import RowMargin from "./RowMargin";
import Footer from "./Footer";
import NotLoggedInMessage from "./NotLoggedInMessage";
import { useMyContext } from '../MyContext';

export default function Help(props) {
  const myContext = useMyContext();

  useEffect(() => {
    //console.log("IN HERE Help UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
    <React.Fragment>
      {props.user && props.user.length > 0 && <div style={{padding: "20px"}} dangerouslySetInnerHTML={{ __html: myContext["data"]["helpHTML"] }}></div>}
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
      {props.user && props.user.length > 0 && <div dangerouslySetInnerHTML={{ __html: {helpHTML} }}></div>}
*/
