import React, { useEffect, useState, useContext } from "react";

import { Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

//Components
import RowMargin from "./RowMargin";
import Footer from "./Footer";
import NotLoggedInMessage from "./NotLoggedInMessage";
//import EditAdminConfig from "./EditAdminConfig";
import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';

export default function Admin(props) {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [failedMessages, setFailedMessages] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState("");

  const getAdminData = async (id) => {
    console.log("IN getAdminData - api/adminData")
    try {
      await fetch("api/getAdminData")
        .then(response => response.json())
        .then(data => {
          console.log("data = " + JSON.stringify(data));
          setFailedMessages(data["failed_messages"]);
          setUsers(data["users"]);
          setFeedback(data["feedback"]);
          setId(data["id"]);
        })
    }
    catch (e) {
      console.log(`Failed to getAdminData`);
      console.log(e);
      console.log(e.stack);
    }
  }
  function getConfig() {
    var configObj = {};
    try {
         configObj["adminConfig"] = {
            "collection": "adminConfig",
            "name": "Admin Config",
            "showLikes": false,
            "buttonName": "Save",
            "security": {
               "create": "AllUsers",
               "edit": "AllUsers",
               "delete": "None",
               "view": "AllUsers",
            },
            flags: "",
            "fields": [
               {
                  placeholder: "",
                  display_name: "Config ID",
                  name: "id",
                  input_type: "display",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Admin Email 1",
                  name: "adminEmail1",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Admin Email 2",
                  name: "adminEmail2",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Admin Email 3",
                  name: "adminEmail3",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Admin Tool ChatBot URL",
                  name: "adminToolChatBot",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Agent URL",
                  name: "agentURL",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Failed Responses",
                  name: "failedResponses",
                  input_type: "list",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Environment Name",
                  name: "environmentName",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Help HTML",
                  name: "helpHTML",
                  input_type: "textarea",
                  colspan: 3,
                  text_area_num_rows: 40,
                  display_x_rows: 40,
                  display_as_html: true,
                  default: "",
                  required: true
               },
               {
                  placeholder: "Creation Date",
                  display_name: "Creation Date",
                  name: "creationDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Update Date",
                  display_name: "Update Date",
                  name: "updateDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               }
            ],
            "table": [
               { selector: (row) => row.id, name: "Admin ID", sortable: true, minWidth: "10%" }
            ],
            "filterField": "id",
            "exportFields": ["id"],
            "otherTableOptions": { "paginationPerPage": 20 },
         }
      configObj["failed_messages"] = {
        "collection": "messages",
        "defaultSort": "kevin",
        "name": "Messages",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "None",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "FAQ ID",
            name: "id",
            input_type: "display",
            default: "",
            required: false
          },
          {
            placeholder: "",
            display_name: "Request",
            name: "request",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Response",
            name: "response",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Date",
            name: "creationDate",
            input_type: "text",
            default: "",
            required: true
          },
        ],
        "table": [
          { selector: (row) => row.user_id, name: "User ID", sortable: true, minWidth: "10%" },
          { selector: (row) => row.agent_id, name: "Agent ID", sortable: true, minWidth: "10%" },
          { selector: (row) => row.request, name: "Request", sortable: true, minWidth: "10%" },
          { selector: (row) => row.response, name: "Response", sortable: true, minWidth: "50%" }
        ],
        "filterField": "response",
        "exportFields": ["request", "response"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
      configObj["feedback"] = {
        "collection": "feedback",
        "defaultSort": "kevin",
        "name": "Feedback",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "None",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "User",
            name: "user",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Feedback",
            name: "feedback",
            input_type: "text",
            default: "",
            required: true
          }
        ],
        "table": [
          { selector: (row) => row.user, name: "User", sortable: true, minWidth: "25%" },
          { selector: (row) => row.feedback, name: "Feedback", sortable: true, minWidth: "25%" }
        ],
        "filterField": "feedback",
        "exportFields": ["user", "feedback"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
      configObj["users"] = {
        "collection": "users",
        "defaultSort": "kevin",
        "name": "Users",
        "showLikes": false,
        "buttonName": "Save",
        "security": {
          "create": "AllUsers",
          "edit": "AllUsers",
          "delete": "None",
          "view": "AllUsers",
        },
        flags: "",
        "fields": [
          {
            placeholder: "",
            display_name: "User",
            name: "user",
            input_type: "text",
            default: "",
            required: true
          },
          {
            placeholder: "",
            display_name: "Roles",
            name: "roles",
            input_type: "text",
            default: "",
            required: true
          }
        ],
        "table": [
          { selector: (row) => row.userId, name: "User", sortable: true, minWidth: "25%" },
          { selector: (row) => row.roles, name: "Roles", sortable: true, minWidth: "25%" }
        ],
        "filterField": "userId",
        "exportFields": ["userId", "roles"],
        "otherTableOptions": { "paginationPerPage": 10 },
      }
    }
    catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }
  const config = getConfig();

  useEffect(() => {
    async function loadAdminData() {
      console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
      const data = await getAdminData();
      console.log("adminData = " + JSON.stringify(data));
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      {props.user && props.user.length > 0 && props.showLoginErrorMessage &&
        <>
          <Row>
            <Col>
              <div width="100%">
                <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                  <Grid item xs={12}>
                    <Paper style={superAppStyle.cardStyle} elevation={8}>
                      <SuperAppObject style={superAppStyle} title="Failed Messages" config={config["failed_messages"]} user={props.user} userDeep={props.userDeep} collection_name="messages" input_data={failedMessages} display_state="BASIC_TABLE" canEdit="false" />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={superAppStyle.cardStyle} elevation={8}>
                    <SuperAppObject style={superAppStyle} title="Feedback" config={config["feedback"]} user={props.user} userDeep={props.userDeep} collection_name="feedback" input_data={feedback} display_state="BASIC_TABLE" canEdit="false" />
                  </Paper>
                </Grid>
              </Grid>
            </Col>
          </Row>
          <Row>
            <Col>
              <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={superAppStyle.cardStyle} elevation={8}>
                    <SuperAppObject style={superAppStyle} title="Users" config={config["users"]} user={props.user} userDeep={props.userDeep} collection_name="users" input_data={users} display_state="BASIC_TABLE" canEdit="false" />
                  </Paper>
                </Grid>
              </Grid>
            </Col>
          </Row>
          <Row>
            <Col>
              <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                <Grid item xs={12}>
                  <Paper style={superAppStyle.cardStyle} elevation={8}>
                    <SuperAppObject style={superAppStyle} title="Admin Config" config={config["adminConfig"]} user={props.user} userDeep={props.userDeep} collection_name="adminConfig" id={id} display_state="DISPLAY" />
                  </Paper>
                </Grid>
              </Grid>
            </Col>
          </Row>
        </>
      }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
          <Row>
            <Col style={{"padding": "40px"}}>
                <EditAdminConfig user={props.user} />
            </Col>
          </Row>
*/
