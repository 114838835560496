
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useContext } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SuperAppObject from "../components/superappobject/SuperAppObject";
import GlobalContext from '../GlobalContext';


import { withRouter } from "react-router";
import './Home.css';

//Components
import RowMargin from "../components/RowMargin";
import Feedback from "../components/Feedback";
import Stats from "../components/Stats";

const divStyle = {
   width: "100%",
   'padding': "25px 50px"
};
//'font-size': '24px',

function Home(props) {

   const globalData = useContext(GlobalContext);
   const [currentTab, setCurrentTab] = useState(props.currentTab);
   const superAppStyle = useContext(GlobalContext)["superAppStyle"];

   const containerRef = React.useRef(null);

   function getConfig() {
      var configObj = {};
      try {
         //setSortData({selector: "creationDate", asc: true});
         configObj["test_plan"] = {
            "collection": "test_plan",
            "name": "Test Plan",
            "showLikes": false,
            "buttonName": "Save",
            "security": {
               "create": "AllUsers",
               "edit": "AllUsers",
               "delete": "None",
               "view": "AllUsers",
            },
            flags: "",
            "fields": [
               {
                  placeholder: "",
                  display_name: "ID",
                  name: "id",
                  input_type: "display",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Question",
                  name: "question",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Answer",
                  name: "answer",
                  input_type: "textarea",
                  colspan: 3,
                  default: "",
                  required: true
               }
            ],
            "table": [
               { selector: (row) => row.question, name: "Question", sortable: true, minWidth: "45%" },
               { selector: (row) => row.answer, name: "Answer", sortable: true, minWidth: "45%" }
            ],
            "filterField": "question",
            "exportFields": ["question", "answer"],
            "otherTableOptions": { "paginationPerPage": 20 },
         }
         configObj["faqs"] = {
            "collection": "faqs",
            "name": "FAQs",
            "showLikes": false,
            "buttonName": "Save",
            "security": {
               "create": "AllUsers",
               "edit": "AllUsers",
               "delete": "None",
               "view": "AllUsers",
            },
            flags: "",
            "fields": [
               {
                  placeholder: "",
                  display_name: "FAQ ID",
                  name: "id",
                  input_type: "display",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Question",
                  name: "question",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Answer",
                  name: "answer",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "Creation Date",
                  display_name: "Creation Date",
                  name: "creationDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Update Date",
                  display_name: "Update Date",
                  name: "updateDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               }
            ],
            "table": [
               { selector: (row) => row.id, name: "FAQ ID", sortable: true, minWidth: "10%" },
               { selector: (row) => row.question, name: "Question", sortable: true, minWidth: "45%" },
               { selector: (row) => row.answer, name: "Answer", sortable: true, minWidth: "45%" },
               {
                  selector: (row) => row.creationDate, name: "Creation Date", sortable: true, sortOrder: "desc", minWidth: "10%",
                  cell: row => (row.creationDate ? row.creationDate.substring(0, 10) : "Unknown")
               }
            ],
            "filterField": "agentName",
            "exportFields": ["agentName", "agentDescription", "firstPhrase"],
            "otherTableOptions": { "paginationPerPage": 20 },
         }
         configObj["agents"] = {
            "collection": "agents",
            "name": "Agents",
            "showLikes": false,
            "buttonName": "Save",
            "security": {
               "create": "AllUsers",
               "edit": "AllUsers",
               "delete": "createdBy",
               "view": "AllUsers",
            },
            flags: "attachments filter_by_user quickReplies",
            "subTables": [
               configObj["test_plan"]
            ],
            "fields": [
               {
                  placeholder: "",
                  display_name: "Agent Status",
                  name: "status",
                  input_type: "display",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Agent ID",
                  name: "id",
                  input_type: "display",
                  link: "[agentURL]/chat/WWWW/[id]",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Agent Type",
                  name: "agentType",
                  input_type: "dropdown",
                  values: "adminConfig.agentTypes", //[ "NEW_AGENT_TYPE", "FAQ", "PDF_QA", "DIALOGFLOW", "WEBHOOK"],
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Model Type",
                  name: "modelType",
                  input_type: "dropdown",
                  values: ["Gemini", "Open AI"],
                  default: "",
                  help: "We recommend you start with Gemini first. If you can't get the results you desire you can try Open AI",
                  conditional: "agentType == FAQ",
                  required: true
               },
               {
                  placeholder: "A short name for the agent",
                  display_name: "Agent Name",
                  name: "agentName",
                  input_type: "text",
                  help: "This is only informational",
                  default: "",
                  required: true
               },
               {
                  placeholder: "A description of the agent",
                  display_name: "Agent Description",
                  name: "agentDescription",
                  input_type: "textarea",
                  colspan: 3,
                  required: false
               },
               {
                  placeholder: "Enter a personalization prompt to control the answers to customer queries more precisely",
                  display_name: "Prompt",
                  name: "prompt",
                  input_type: "textarea",
                  text_area_num_rows: 10,
                  colspan: 3,
                  default: "",
                  conditional: "agentType == PDF_QA",
                  required: false
               },
               {
                  placeholder: "Enter a personalization prompt to control the answers to customer queries more precisely",
                  display_name: "Prompt",
                  name: "prompt",
                  input_type: "textarea",
                  text_area_num_rows: 10,
                  colspan: 3,
                  default: "",
                  conditional: "agentType == WEBSITE_QA",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Indexing Complete",
                  name: "indexingComplete",
                  input_type: "display",
                  default: "",
                  conditional: "agentType == PDF_QA",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Indexing Complete",
                  name: "indexingComplete",
                  input_type: "display",
                  default: "",
                  conditional: "agentType == WEBSITE_QA",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Webhook URL",
                  name: "webhookURL",
                  input_type: "text",
                  colspan: 3,
                  default: "",
                  conditional: "agentType == WEBHOOK",
                  required: true
               },
               {
                  placeholder: "Type/paste in any data that can be used to answer questions from customers",
                  display_name: "FAQs",
                  name: "faqs",
                  input_type: "textarea",
                  text_area_num_rows: 20,
                  colspan: 3,
                  display_x_rows: 20,
                  default: "",
                  conditional: "agentType == FAQ",
                  required: true
               },
               {
                  placeholder: "Website you'd like to scan data (http://www.example.com)",
                  display_name: "Website to scan",
                  name: "website",
                  input_type: "text",
                  default: "",
                  conditional: "agentType == WEBSITE_QA",
                  required: true
               },
               {
                  placeholder: "Select a second agent to call and try and answer",
                  display_name: "Next Agent",
                  name: "nextAgent",
                  input_type: "dropdown",
                  values: "myObjects",
                  default: "",
                  conditional: "agentType == FAQ",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "FAQ Prompt",
                  name: "faqPrompt",
                  input_type: "textarea",
                  text_area_num_rows: 5,
                  colspan: 3,
                  default: "",
                  conditional: "agentType == FAQ",
                  required: false
               },
               {
                  placeholder: "The welcome message to the user",
                  display_name: "First Phrase",
                  name: "firstPhrase",
                  input_type: "textarea",
                  help: "This would a be message that greets the users when they first start using the agent. For example, Welcome to my chat bot. It can answer questions like how is the weather or where can I buy a good wine",
                  colspan: 3,
                  required: true
               },
               {
                  placeholder: "The text that is displayed when somebody types 'help'",
                  display_name: "Help Text",
                  name: "helpText",
                  input_type: "textarea",
                  text_area_num_rows: 3,
                  default: "",
                  colspan: 3,
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Dialogflow Agent ID",
                  name: "dialogflowAgentId",
                  input_type: "text",
                  default: "",
                  conditional: "agentType == DIALOGFLOW",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Dialogflow Project ID",
                  name: "dialogflowProjectId",
                  input_type: "text",
                  default: "",
                  conditional: "agentType == DIALOGFLOW",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Dialogflow Location",
                  name: "dialogflowLocation",
                  input_type: "text",
                  default: "",
                  conditional: "agentType == DIALOGFLOW",
                  required: true
               },
               {
                  placeholder: "The color of the chatbot/agent header (e.g., blue, red, green or #FF0000 format allowed)",
                  display_name: "Header Color",
                  name: "headerColor",
                  input_type: "color",
                  default: "#32a111",
                  required: true,
                  validation: { isColor }
               },
               {
                  placeholder: "The color of the chatbot/agent background",
                  display_name: "Background Color",
                  name: "backgroundColor",
                  input_type: "color",
                  default: "#FFFFFF",
                  required: true,
                  validation: { isColor }
               },
               {
                  placeholder: "For this next field, you can add in some extra tokens that will be replaced at runtime: {timeOfDay} returns morning, afternoon, or night, and {user} will populate the user's name to make the interaction more personable",
                  display_name: "",
                  name: "",
                  input_type: "none",
                  required: false
               },
               {
                  placeholder: "An image that shows up when the chatbot starts",
                  display_name: "First Image",
                  name: "firstImage",
                  input_type: "text",
                  required: false
               },
               {
                  placeholder: "The text inside the chat input field",
                  display_name: "Input Helper Text",
                  name: "inputHelperText",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "The animated Gif that draws while waiting for a response.",
                  display_name: "Animation GIF",
                  name: "animationGIF",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "This configures the text under the animation image",
                  display_name: "Animation Caption",
                  name: "animationCaption",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "A colon (:) separated list of quick replies",
                  display_name: "Quick Replies",
                  name: "quickReplies",
                  input_type: "textarea",
                  help: "These are buttons which make it easy for the user to cick on versus having to type in their response.  The values entered here only show up when you type help. They are most likely going to be sample questions you can ask the bot to get the user started.", 
                  colspan: 3,
                  default: "",
                  required: false
               },
               {
                  header: "Administration",
                  colspan: 3
               },
               {
                  placeholder: "",
                  display_name: "Authorized Editors",
                  name: "userIds",
                  input_type: "list",
                  default: "",
                  required: false
               },
               {
                  placeholder: "A colon separated list of IP addresses to block",
                  display_name: "Blocked IPs",
                  name: "blockedIPs",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "Put a message here and the agent will be disabled for ALL",
                  display_name: "Disabled",
                  name: "disabled",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "The message that will be shown if the agent is disabled",
                  display_name: "Disabled Agent Message",
                  name: "disabledMessage",
                  input_type: "textarea",
                  colspan: 3,
                  help: "You can use html in this field to render tables or pictures.  You could also change font sizes, styles, or colors.",
                  default: "",
                  required: false
               },
               {
                  placeholder: "A message you'd like to say to user if you can't answer their question",
                  display_name: "Failed Message",
                  name: "failedMessage",
                  input_type: "textarea",
                  colspan: 3,
                  help: "This would probably be something like. If you'd like more information contact info@xxx.com",
                  default: "",
                  required: false
               },
               {
                  placeholder: "A message you'd like to say to user if you exceed your current plan message count",
                  display_name: "Exceed Plan Message",
                  name: "exceedPlanMessage",
                  input_type: "textarea",
                  colspan: 3,
                  help: "This would probably be something like. If you'd like more information contact info@xxx.com",
                  default: "",
                  required: false
               },
               {
                  placeholder: "Wix Instance Id",
                  display_name: "Wix Instance Id",
                  name: "wixInstanceId",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Subscription Name",
                  display_name: "Subscription Name",
                  name: "subscriptionName",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Subscribed?",
                  display_name: "Subscribed",
                  name: "subscribed",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Locked?",
                  display_name: "Locked",
                  name: "locked",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "The date you subscribed",
                  display_name: "Subscribe Date",
                  name: "subscribeDate",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "The date you cancelled",
                  display_name: "Cancellation Date",
                  name: "cancellationDate",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Created By",
                  display_name: "Created By",
                  name: "createdBy",
                  input_type: "text",
                  readonly: true,
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Creation Date",
                  display_name: "Creation Date",
                  name: "creationDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Update Date",
                  display_name: "Update Date",
                  name: "updateDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Embedded Code Snippet",
                  name: "id",
                  input_type: "display",
                  show_copy_button: true,
                  display_x_rows: 10,
                  display_template: `<div id='iframeContainer' class='toggleBtn' style='display: none;'>
  <iframe id='myIframe' class='myIframe' src='[agentURL]/chat/WWWW/[id]' width='400' height='600' scrolling='no'></iframe>
</div>
<button id='toggleBtn' class='toggleBtn'>
     <img src='https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg' height='40px', width='40px'></img>
</button>
<style>
   .myIframe {
     border: 0;
     border-radius: 20px;
     overflow: hidden;
   }
   .toggleBtn {
     z-index: 999;
     position: fixed;
     top: 0px;
     right: 48px;
   }
</style>
<script>
const toggleBtn = document.getElementById('toggleBtn');
const iframeContainer = document.getElementById('iframeContainer');
toggleBtn.addEventListener('click', () => {
iframeContainer.style.display = iframeContainer.style.display === 'none' ? 'block' : 'none';
});
</script>`,
                  colspan: 3,
                  default: "",
                  required: false
               },
            ],
            "table": [
               { selector: (row) => row.id, name: "Agent ID", sortable: true, minWidth: "20%" },
               { selector: (row) => row.user, name: "User", sortable: true, minWidth: "10%" },
               { selector: (row) => row.agentName, name: "Agent Name", sortable: true, minWidth: "30%" },
               { selector: (row) => row.agentType, name: "Agent Type", sortable: true, minWidth: "10%" },
               {
                  selector: (row) => row.creationDate, name: "Creation Date", sortable: true, sortOrder: "desc", minWidth: "10%",
                  cell: row => (row.creationDate ? row.creationDate.substring(0, 10) : "Unknown")
               }
            ],
            "filterField": "agentName",
            "exportFields": ["id", "agentName", "agentDescription", "agentType", "prompt", "faqs", "firstPhrase", "webhookURL", "dialogflowAgentId", "dialogflowProjectId", "dialogflowLocation", "helpText", "headerColor", "inputHelperText", "animationGIF", "animationCaption", "creationDate", "updateDate"],
            "otherTableOptions": { "paginationPerPage": 20 },
         }
      }
      catch (e) {
         console.log("Failed to configure: " + e);
         console.log(e.stack);
      }
      return configObj;
   }

   const isColor = (strColor) => {
console.log("Is color");
      const s = new Option().style;
      s.color = strColor;
 //     if (globalData.debug) 
  console.log("checking color for " + strColor + "," + (s.color !== ''));
      return s.color !== '';
   }

   function validateObject(obj) {

      // check to see if the agent is ready to be used
      // check for attachments, indexes completed..complex formatting of fields like FAQ
      // and update the status during the save option 
      if (globalData.debug) console.log("Validating object");
      let statusError = "";
      if (!("indexingComplete" in obj) && obj.agentType === 'PDF_QA') {
         statusError += "Agent Not Ready for use.  Please upload files to create the index";
      }

      /* if (!isColor(obj.headerColor)) {
         statusError += ", Header Color is not a valid color";
      } */

      if (statusError.length === 0)
         return { "status": "Agent ready for use" }
      else
         return { "status": statusError }
   }
   //cell: row => row.deal.substring(0,30) },
   const config = getConfig();

   const scrollToTop = () => {
      if (containerRef.current !== null) {
         //containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'top' });
         containerRef.current.scrollIntoView(true);
      }
   };

   return (
      <React.StrictMode>
         <Container ref={containerRef} fluid>
            <Row>
               <Col style={divStyle}>
                  {currentTab === 'home' &&
                     <React.Fragment>
                        <Grid className={superAppStyle.classes.paper} style={superAppStyle.flex100} spacing={2}>
                           <Grid item xs={12}>
                              <Paper style={superAppStyle.cardStyle} elevation={8}>
                                 <SuperAppObject depth={1} id={props.id} style={superAppStyle} config={config["agents"]} deepConfig={config} user={props.user} userDeep={props.userDeep} collection_name="agents" display_state={props.display_state} validate_object={validateObject} scroll_to_top={scrollToTop} showAgent="true" />
                              </Paper>
                           </Grid>
                        </Grid>
                     </React.Fragment>
                  }
                  {currentTab === 'stats' &&
                     <Stats user={props.user} userDeep={props.userDeep} />
                  }
               {currentTab === 'contact' &&
                  <React.Fragment>
                     <RowMargin />
                     <p> Please contact support at support@genaisolutions.ai for more information</p>
                     <Feedback user={props.user} />
                  </React.Fragment>
               }
            </Col>
         </Row>
      </Container>
	</React.StrictMode >
	  );
}
export default withRouter(Home);
/*

*/
