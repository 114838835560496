import React, { useEffect } from "react";

//Components
import RowMargin from "./RowMargin";
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";
import User from "../pages/User";

export default function UserRoute(props) {

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
    <React.Fragment>
      {props.user && props.user.length > 0 &&
        <User user={props.user} />
      }
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
