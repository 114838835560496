import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import GlobalContext from './GlobalContext';
import { useMyContext } from './MyContext';

import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";

import { Button } from "react-bootstrap";

//Components
import Header from "./components/Header";
import Admin from "./components/Admin";
import Agents from "./components/Agents";
import Help from "./components/Help";
import UserRoute from "./components/UserRoute";
import StatsRoute from "./components/StatsRoute";
import Contact from "./components/Contact";
import ChatBot from "./components/ChatBot";
import WixLoginCallback from "./components/WixLoginCallback";
import Subscriptions from "./components/Subscriptions";
import Stripe from "./components/Stripe";
import Image from 'react-bootstrap/Image';

//Pages
//import Home from "./pages/Home";
//import SearchResults from "./pages/SearchResults";
//import User from "./pages/User";
import "./index.css";


function App() {
  const [id, setId] = useState("");
  const [user, setUser] = useState("");
  const [userDeep, setUserDeep] = useState({});
  const [iframeLogin, setIframeLogin] = useState(false);
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
  const globalData = useContext(GlobalContext);
  const { data, setData } = useMyContext();

  const [users, setUsers] = useState({
    data: [],
    status: "IDLE"
  });
  const [reference, setReference] = useState({
    data: [],
    status: "IDLE"
  });
  setTimeout(() => setShowLoginErrorMessage(true), 2000)
  const [chatWindowState, setChatWindowState] = useState(false);

  const toggleChatWindow = () => {
    if (!chatWindowState)
       setChatWindowState(true);
    else
       setChatWindowState(false);
  };

  async function loadUser() {
    try {
      const response = await fetch("/api/user");
      const data = await response.json();
      if (globalData.debug) console.log("XXXY= " + data.user);
      setUser(data.user);
      //await loadUserDeep(data.user);
    } catch (e) {
      console.log("Failed to load user!");
      setUser("");
    }
  }
  async function loadUserDeep(userId) {
    try {
      if (globalData.debug) console.log("#######User = " + userId);
      const response = await fetch("/api/getObjects/users/where/userId/==/" + userId);
      const data = await response.json();
      setUserDeep(data[0]);
      console.log("#######Data User = " + JSON.stringify(data));
    } catch (e) {
      console.log("Failed to load user!");
      setUser("");
    }
  }
  async function loadReference() {
    try {
      const response = await fetch("/api/getObjects/reference");
      const data = await response.json();
      setReference({ data: data, status: "IDLE" });
    } catch (e) {
      console.log("Failed to load Reference!");
    }
  }
  async function registerLoggedInUser(userId) {
    try {
      setUser(userId);
      const response1 = await fetch("/api/addUser/" + userId);
      const response2 = await fetch("/api/createSession/" + userId);
      const data = await response2.json();
      console.log("Session = " + data.sessionId);

    } catch (e) {
      console.log("Failed to create session!");
    }
  }

  useEffect(() => {
    async function loadPage() {
       console.log(window.location.search);
       console.log(window.location);
       const urlSearchParams = new URLSearchParams(window.location.search);
       const params = Object.fromEntries(urlSearchParams.entries());
       console.log("PARAMS = " + JSON.stringify(params));
        try {
          const response = await fetch("/api/getAdminConfig");
          const data = await response.json();
          console.log(JSON.stringify(data));
          //setData(data);
        }
        catch (e) {
          console.log(`Failed to load adminConfig`);
          console.log(e);
          console.log(e.stack);
        }
       if ("instance" in params) {
          console.log(" in here getWixInfo");
          const response = await fetch("/api/getWixInfo/" + params["instance"]);
          const data = await response.json();
          console.log("DATA = " + JSON.stringify(data));
          setUser(data["user"]);
          setId(data["id"]);
          setIframeLogin(true);
          Cookies.set("session", data["user"] + "-" + data["id"]);
       }
    }
    loadPage();
  }, [window.location, window.location.search]);

  useEffect(() => {
    if (globalData.debug) console.log("IN HERE App UseEffect");
    async function loadPage() {
      //await loadUser(); KAD Aug 1, 2024...perhaps put back
      //await loadReference(); don't need now Aug 13, 2024
      if (user && user.length > 0)
         await loadUserDeep(user);
    }
    loadPage();
  }, [user]);

  const style1 = {
    width: "100%",
    height: "100%",
  };
  const style2 = {
    width: "100%",
    //height: "80vh",
    height: "100%",
  };

  function isAdmin() {
    if (userDeep && userDeep.roles && userDeep.roles.toLowerCase().indexOf("admin") >= 0) return true;
    return false;
  }
  /* TODO do I need this users variable below in the Header */
  /* it the callback below which ultimately sets the User for the session */
/*
      {false && chatWindowState && 
      <>
        <ChatBot 
          iframeSrc="https://agent-prod.genaisolutions.ai/chat/WWWW/O4hiiO4ujrdYT2g1534r" 
        />
      </>
      }
PUT AFTER SWITCH
                <Button style={{position: "absolute", top: "20px", right: "20px"}} onClick={toggleChatWindow}>Chat</Button>
*/
  return (
    <>
      <button style={{position: "fixed", top: "60px", right: "20px", zIndex: "999"}} onClick={toggleChatWindow}><img src="https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg" height="40px" width="40px"></img></button>
      {chatWindowState && 
      <>
        <ChatBot 
          iframeSrc={data && data["adminToolChatBot"]} 
        />
      </>
      }
      <Router>
      <Header users={users} userDeep={userDeep} user={user} reference={reference} callback={registerLoggedInUser} iframeLogin={iframeLogin} />
        <div style={style1}>
          <main>
            <div className="main-area" style={style2}>
              <Switch>
                {isAdmin() &&
                  <Route exact path="/admin">
                    <Admin user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                  </Route>
                }
                <Route exact path="/wix">
                  <Agents user={user} id={id} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/">
                  <Agents user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/subscribe">
                  <Stripe user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/agents">
                  <Agents user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/stats">
                  <StatsRoute user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/help">
                  <Help user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route exact path="/contact">
                  <Contact user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
                <Route path="/login-callback">
                  <WixLoginCallback />
                </Route>
                <Route path="/subscriptions">
                  <Subscriptions />
                </Route>
                <Route path="/stripe">
                  <Stripe />
                </Route>
                <Route path="/user">
                  <UserRoute user={user} showLoginErrorMessage={showLoginErrorMessage} />
                </Route>
              </Switch>
            </div>
          </main>
        </div>
      </Router>
    </>
  );
}

export default App;
/* for the future
              <Route
                path="/search/:search"
                render={() => {
                  return (
                    <>
                    <SearchResults user={userDeep}/>
                    </>
                  );
                }}
              />
      <Image source={"https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg"} height="40px" width="40px" style={{position: "absolute", bottom: 0, right: 0}}></Image>
*/
