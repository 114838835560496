import React, { useEffect } from "react";

//Components
import RowMargin from "./RowMargin";
import Footer from "./Footer";
import NotLoggedInMessage from "./NotLoggedInMessage";
import Home from "../pages/Home";

export default function Stats(props) {

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
    <React.Fragment>
      {props.user && props.user.length > 0 && <Home currentTab="stats" display_state="TABLE" user={props.user} userDeep={props.userDeep} />}
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
