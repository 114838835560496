import React, { useState } from "react";

import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

//Components
import TopMargin from "../components/TopMargin";
import RowMargin from "../components/RowMargin";
import Loading from "../components/Loading";

export default function UploadFile(props) {

  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE"
  });

  const [documentData, setDocumentData] = useState({});

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const handleInputChange = e => {
    const target = e.currentTarget;
    const localFileNames = [];
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        //value = e.target.files[0];
        value = e.target.files;
        for (let x = 0; x < value.length; x++) {
          console.log("File Name = " + value[x].name);
          localFileNames.push(props.filePrefix + value[x].name);
        }
        setFileNames(localFileNames);
        console.log("files = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(props.filePrefix);
    console.log(name);
    if (props.filePrefix && name === 'fileName') {
      setFileName(props.filePrefix + value.name);
    }
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };

  const handleSubmit = async () => {
    try {
      setFormData({
        ...formData,
        status: "SUBMITTED"
      });


      const uploadData = new FormData();
      console.log(formData.data);
      Object.keys(formData.data).forEach(key => {
        console.log("KEY = " + key);
        if (fileName) {
          for (let x = 0; x < formData.data[key].length; x++)
            //uploadData.append(key, formData.data[key], fileName);
            uploadData.append(key + "-" + x, formData.data[key][x], fileNames[x]);
        }
        else
          uploadData.append(key, formData.data[key]);
      });
      uploadData.append("id", props.id);
      uploadData.append("user_id", props.user_id);
      uploadData.append("agent_type", props.agent_type);
      console.log("uploadData = " + JSON.stringify(uploadData));


      const response = await fetch("/api/upload", {
        method: "post",
        body: uploadData
      });

      const data = await response.json();
      if (response.status === 200) {
        setFormData({
          data: data,
          status: "SUCCESS"
        });
        resetFileInput();

        setDocumentData(data);
        /* callback to save the agent object after 3 seconds */
        setTimeout(function () {
          props.callback(fileNames);
        }, 3000)

      } else {
        setFormData({
          data: data,
          status: "ERROR"
        });
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR"
      });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <TopMargin />
          <Form>
            <Form.Group controlId="uploadFile">
              <Form.Label>Upload File(s)</Form.Label>
              <Form.Control
                key={fileInputKey}
                type="file"
                name="fileName"
                placeholder="Select a file or files"
                className="form-control"
                onChange={handleInputChange}
                accept="pdf"
                multiple
              />
            </Form.Group>
            <Form.Group>
            </Form.Group>
            <RowMargin />
            <Button variant="primary" type="button" onClick={handleSubmit}>
              Upload
            </Button>&nbsp;
            <Button variant="primary" type="button" onClick={props.handleUploadFileCancel}>
              Cancel
            </Button>
          </Form>
        </Col>
      </Row>
      {formData.status === "SUBMITTED" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "ERROR" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="danger">{formData.data && "message" in formData.data ? formData.data.message : "Failed to upload file, please try again"}</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="success">{formData.data && "message" in formData.data ? formData.data.message : "Successfully uploaded file!"}</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {documentData && Object.keys(documentData).length > 0 && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
}
