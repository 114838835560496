import React, { useEffect, useState, useContext } from "react";

import GlobalContext from '../GlobalContext';
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";

// generate select dropdown option list dynamically
function Options({ options }) {
  return (
    options.map(option =>
      <option>
        {option}
      </option>)
  );
}

export default function Feedback(props) {
  const globalData = useContext(GlobalContext);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    data: props.data,
    status: "IDLE"
  });
  var helpOptions = ["Provide Feedback", "Report a Bug", "Ask a question"];

  const handleInputChange = e => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE"
    });
  };

  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED"
      });

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE"
        });
      }
      else {
        setValidated(false);
        var dataToSave = formData.data
        dataToSave["user"] = props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
        const response = await fetch("/api/saveObject/feedback", {
          headers: { "Content-Type": "application/json" },
          method: "post",
          body: JSON.stringify(dataToSave)
        });
        const data = await response.json();
        if (data.success) {
          if (globalData.debug) console.log(data);
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS"
          });
          if (globalData.debug) console.log(JSON.stringify(formData));

        } else {
          throw Error("Failed to add new " + props.config.name)
        }
      }
    } catch (e) {
      console.log(`Failed to add feedback`);
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR"
      });
    }
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
    }
    loadComponent();
  }, []);

  return (
    <>
      {formData.status === "IDLE" && (
        <React.Fragment>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="requestType">
              <Form.Label>Request Type</Form.Label>
              <Form.Control
                required
                as="select"
                type="select"
                name="requestType"
                onChange={handleInputChange}
              >
                <option></option>
                <Options options={helpOptions} />
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please provide a Request Type
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="feedback">
              <Form.Label>Provide the Details For Your Request</Form.Label>
              <Form.Control as="textarea" rows={3}
                required
                name="feedback"
                onChange={handleInputChange}
                placeholder="Enter your comment/request/question"
              />
              <Form.Control.Feedback type="invalid">
                Please provide some comments
              </Form.Control.Feedback>
            </Form.Group>
            <RowMargin />
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant="success">Successfully added</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  )
}
