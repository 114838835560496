import React from 'react';


/* THIS IS IN HERE BUT USED TO BE THE BORDER OF THE IFRAME */
const xxx = {
   width: "50%",
   'margin': '10px',
   //border: '2px solid black',
   borderRadius: '5px !important',
   overflow: 'hidden'
};

const backgroundColor = "#dfeea3"; /* light Green */
const backgroundColorTableHeader = "#32a111"; /* dark green */
const contextBackgroundColor = backgroundColor; //'#cb4b16';
const contextTextColor = '#FFFFFF';
const superAppStyle = {
      attributes: {
         backgroundColor: "#dfeea3", /* light Green */
         backgroundColorTableHeader: "#32a111" /* dark green */
      },
      theme: {
         text: {
         },
         background: {
           /* default: "#e5f1ff" */
           default: backgroundColor, /* light green */
         },
         context: {
           background: backgroundColor, //'#cb4b16',
           text: '#FFFFFF',
           width: '40%'
         },
         divider: {
         },
         action: {
           button: 'rgba(0,0,0,.54)',
           hover: 'rgba(0,0,0,.08)',
           disabled: 'rgba(0,0,0,.12)',
         },
       },
       customStyles : {
	header: {
		style: {
			minHeight: '56px',
			width: '100%'
		},
	},
        subHeader: {
		style: {
			/* backgroundColor: "#e5f1ff",*/
			backgroundColor: backgroundColor, 
			minHeight: '52px',
		},
        },
        contextMenu: {
		style: {
			backgroundColor: contextBackgroundColor,
			fontSize: '18px',
			fontWeight: 400,
			color: contextTextColor,
			paddingLeft: '16px',
			paddingRight: '8px',
			transform: 'translate3d(0, -100%, 0)',
			transitionDuration: '125ms',
			transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
			willChange: 'transform',
		},
		activeStyle: {
			transform: 'translate3d(0, 100%, 0)',
		}
           },
    rows: {
        style: {
            //minHeight: '72px', // override the row height
            fontSize: "1.2 rem",
            //backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'black'
        },
    },
    headCells: {
        style: {
            fontSize: "1.2 rem",
            fontWeight: "700",
            backgroundColor: backgroundColorTableHeader,
            /* backgroundColor: '#007bff', OLD BLUE HEADER Aug 7, 2024 */
            color: 'white'
            //paddingLeft: '8px', // override the cell padding for head cells
            //paddingRight: '8px',
        },
    },
    cells: {
        style: {
            //paddingLeft: '8px', // override the cell padding for data cells
            //paddingRight: '8px',
        },
    },
}, colStyleLeft : {
   width: "50%",
   borderStyle: "solid",
   borderColor: "black",
   borderWidth: "2px 0px 0px 2px", /* 25px top, 10px right, 4px bottom and 35px left */
   fontWeight: "700",
   color: "#fff",
   backgroundColor: backgroundColorTableHeader,
   padding: "10px"
},
rowStyle: {
   width: "100%",
  'font-size': '24px'
},
gridStyle100: {
   /* borderStyle: "solid",
   borderColor: "black", */
   'font-size': '16px',
   width: "100%"
},
gridStyle66: {
   /* borderStyle: "solid",
   borderColor: "black", */
   'font-size': '16px',
   width: "66%"
},
gridStyle33: {
   /* borderStyle: "solid", */
   /* borderColor: "black", */
   'font-size': '16px',
   width: "33%",
   'whiteSpace': 'pre-wrap',
   'overflowWrap': 'break-word'
},
displayName: {
   fontWeight: "700",
   paddingLeft: "3px"
},
displayValue: {
   paddingLeft: "10px",
   fontStyle: "italic"
},
colorContainer: {
   position: "relative",
   display: "flex",
   alignItems: "center"
},
flex100: {
    flex: "100%",
    "max-width": "100%",
    "margin-bottom": "20px",
    "border-radius": "20px"
},
classes: {
  root: {
    flexGrow: 1
  },
  row: {
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: 10,
    margin: 10,
    textAlign: "center",
    'font-size': '24px'
  }
}, 
cardStyle: {
   //"background-color": "#e5f1ff", //Light Blue from before Aug 7, 2024
   "background-color": "#dfeea3", /* light green */
   "border-radius": "20px",
   "padding": "10px",
   "margin": "30px"
}
}

const globalVariables = {"debug":true, superAppStyle: superAppStyle}
const GlobalContext = React.createContext(globalVariables);
export default GlobalContext; 
